import React from "react";
import Footer from "../components/Footer";
import QuoteSection from "../components/QuoteSection";
import DonationForm from "../DonationForm";
import Contact from "../components/Contact";
import Navbar from "../components/Navbar";
import Hero from "../components/Hero";
import "./DonatePage.css";
import heroImg from "../images/chevra-hero.png";
import chevramobile from "../images/chevramobile.png";

import NavbarContextProvider from "../utils/NavbarContextProvider";
import ContactForm from "../components/ContactForm";
import TestSendGrid from "../components/TestSendGrid";
export default function DonatePage() {
  return (
    <NavbarContextProvider>
      <div className="donatePage">
        <div className="desktopImg">
          <img src={heroImg}></img>
        </div>

        <div className="mobileImg">
          <img src={chevramobile}></img>
        </div>

        {/* <Hero /> */}

        <section className="donationFormSection">
          <DonationForm />
        </section>

        {/* <QuoteSection
          backgroundColor={"rgba(255, 157, 137, 0.45)"}
          textColor={"#131f67"}
        >
          “I once learned that one isn't supposed to give specific praise about
          Hashem since once you start you can't stop. If you stop, "is that it?"
          On the same note, I feel like even if I sent you a thank you email
          daily that would be insufficient. "Is that it?" Just once a day? It's
          hard to describe how helpful your monthly and yom tov help is. We
          appreciate it so much. You're doing such great work and are helping
          more than you know. THANK YOU!!!”
        </QuoteSection> */}

        <section
          id={"contact"}
          style={{
            maxWidth: "1900px",
            margin: "0 auto",
          }}
          className="contactSection"
        >
          {/* <Contact /> */}
          {/* <ContactForm /> */}
          {/* SENDGRID */}
          {/* <TestSendGrid /> */}
        </section>

        <Footer />
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "flex-end",
            flexDirection: "column",
            backgroundColor: "#131f67",
            color: "white",
            width: "100%",
            textAlign: "center",
          }}
        >
          <div className="copyrightTextDesktop">
            <p> Copyright Davis Memorial Fund. All Rights Reserved. ©2023</p>
          </div>
        </div>
      </div>
    </NavbarContextProvider>
  );
}
