import React, { useContext } from "react";
import { ThemeContext } from "../utils/NavbarContextProvider";
import "./Hero.css";
import donateBG5 from "../images/donateBG5.svg";
function Hero() {
  const { isNavBarOpen, isManuallyClosed } = useContext(ThemeContext);
  // const handleButtonClick = () => {
  //   window.location.href = "https://davismemorialfund.org/donate/";
  // };
  return (
    <div
      className={`hero ${isNavBarOpen || isManuallyClosed ? "show" : "hide"}`}
    >
      <div className="heroContainer">
        <div className="hero-text">
          <h1
            style={{
              color: "#131f67",
            }}
          >
            Make Your
          </h1>

          <h2
            style={{
              color: "#131f67",
            }}
          >
            Donation Today.
          </h2>
          <p
            style={{
              maxWidth: "25rem",
              color: "#131f67",
            }}
          >
            Partner with DMF to assist deserving families struggling from
            financial distress.
          </p>

          <div className="hero-image-mobile">
            <img src={donateBG5} alt="hero" className="heroimageSizeMobile" />
          </div>

          {/* <button onClick={handleButtonClick}>
            DONATE
            <img src={rightArrow} alt="right arrow" />
          </button> */}
        </div>
        <div className="hero-image">
          <img src={donateBG5} alt="hero" className="heroimageSize" />
        </div>
      </div>
    </div>
  );
}

export default Hero;
